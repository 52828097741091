.plan-container
{
    display: flex;
    flex-direction: column;
}
.plan-blur-2
{
    width: 32rem;
    height: 23rem;
    top: 90rem;
    left: 0;
}
.plan-blur-1
{
    width: 32rem;
    height: 23rem;
    top: 90rem;
    right: 0;
}
.plan--card
{
    padding-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    gap: 4rem;
    color: white;
    padding-bottom: 50px;

}
.plan-card>div>span
{
    font-size: 3rem;
    font-weight: bold;
}
.plan-card>div>svg{
    width: 2rem;height: 2rem;
    fill: white;
}
.plan-card
{
    border: 2px solid white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2rem;
}
.plan-card>button
{
    margin-top: 15px;
    background-color: transparent;
    color: white;
    border-radius: 15px;
    border: 2px solid white;
}

@media screen and (max-width:768px) {
    .plan--card
    {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .plan--card>:nth-child(1)
    {
        width: 70%;
        margin: auto;
    }
    .plan--card>:nth-child(2)
    {
        width: 60%;
        margin: auto;
        transform: none;
    }
    .plan--card>:nth-child(3)
    {
        width: 70%;
        margin: auto;
    }
}