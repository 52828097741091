:root
{
  --lightgray: #D9D9D9;
  --gray:#9c9c9c;
  --orange:#f48915;
  --calorycard:#656565;
  --plancard: linear-gradient(210.41deg,#fa5042 1.14% ,#ffa739 100.75%);
  --appcolor:#3c3f45;
}
.header
{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header--logo
{
    color: white;
    font-family:cursive ;
    font-size: 1.5rem;

}
.header--menu
{
    list-style: none;
    display: flex;
    gap: 2rem;
    color: white;
}
.header--list
{
    cursor: pointer;
}
.header--list:hover
{
    color: #f48915;
}

@media screen and (max-width:768px) {
    .header>:nth-child(2)
    {
        position: fixed;
        right: 2rem;
        top: 1rem;
        z-index: 99;
    }
    .header--menu
    {
        flex-direction: column;
        background-color: var(--appcolor);
        padding: 2rem;
    }


    
}