:root
{
  --lightgray: #D9D9D9;
  --gray:#9c9c9c;
  --orange:#f48915;
  --calorycard:#656565;
  --plancard: linear-gradient(210.41deg,#fa5042 1.14% ,#ffa739 100.75%);
  --appcolor:#3c3f45;
}

.App{
  mix-blend-mode: overlay;
  background-color: var(--appcolor);
  display: flex;
  flex-direction: column;
  gap: 6rem;
  overflow: hidden;
}

::-webkit-scrollbar{
  display: none;
}

.blur
{
  background: rgba(253, 120, 43, 0.69);
  position: absolute;
  border-radius: 50%;
  filter: blur(150px);
  z-index: -9;
}