.program
{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem;
}

.program-header
{
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-size: 3rem;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    font-style: italic;
}
.program-cards
{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: auto;
    gap: 1.8rem;
}
.cards>svg
{
    width: 2rem;
    height: 2rem;
    fill: white;
}
.cards
{
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    width: 20rem;
    height: auto;
    padding: 2rem;
    justify-content: space-between;
    align-items: center;
    border: 2px solid white;
    color: white;
    border-radius: 10px;
}
.cards>button
{
    margin-top: 10px;
    background-color: transparent;
    border: 2px solid var(--orange);
    color: white;
}

.cards:hover
{
    border: 0;
    outline: none;
    background: var(--plancard);
    /* background: linear-gradient(to top ,rgba(78, 75, 75, 0.721), var(--orange), var(--orange),rgba(78, 75, 75, 0.721)); */
}

@media screen and (max-width :786px)
{
    .program-header
    {
        flex-direction: column;
        gap: 1rem;
        font-size: x-large;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
    }
    .program-cards
    {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .cards
    {
        width: 80%;
        margin: auto;
    }
}