:root
{
  --lightgray: #D9D9D9;
  --gray:#9c9c9c;
  --orange:#f48915;
  --calorycard:#656565;
  --plancard: linear-gradient(210.41deg,#fa5042 1.14% ,#ffa739 100.75%);
  --appcolor:#3c3f45;
}
.hero
{
    display: flex;
    justify-content: space-between;
}
.left_h
{
    padding: 2rem;
    padding-top: 1.5rem;
    flex: 3;
    display: flex;
    gap:2rem;
    flex-direction: column;
}
.right_h
{
    position: relative;
    background-color: var(--orange);
    flex: 1;
}

.the-best-ad
{
    margin-top: 4rem;
    background-color: #282d30;
    border-radius: 4rem;
    width: fit-content;
    padding: 20px 23px;
    color: white;
    text-transform: uppercase;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.the-best-ad>span
{
    z-index: 2;
}
.the-best-ad>div
{
    position: absolute;
    background-color:var(--orange) ;
    width: 5.4rem;
    height: 80%;
    left: 8px;
    border-radius: 3rem;
    z-index: 1;
}
.hero-text
{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    text-transform: uppercase;
    font-size: 4.5rem;
    font-weight: bold;
    color: white;
    text-overflow: inherit;
}
.span
{
    font-size: 1rem;
    font-weight: 200;
    text-transform: none;
    width: 80%;
    letter-spacing: 1px;
}
.figures
{
    display: flex;
    gap: 2rem;
}
.figures>div
{
    display: flex;
    flex-direction: column;
}
.figures>div>span:nth-of-type(1)
{
    color: white;
    font-size: 2rem;
}
.figures>div>span:nth-of-type(2)
{
    text-transform: uppercase;
    color: var(--gray);
}

.btn
{
    background-color: white;
    padding: 0.5rem;
    font-weight: bold;
    border: 4px solid transparent;
    transition: 300ms all;
}

.btn:hover
{
    cursor: pointer;
}

.hero-button
{
    display: flex;
    gap: 1rem;
    font-weight: normal; 
}

.hero-button > :nth-child(1)
{
    color:  white;
    background-color: var(--orange);
    width: 8rem;
    /* border-radius: 10px; */
}
.hero-button > :nth-child(2)
{
    color:  white;
    background-color: transparent;
    width: 8rem;
    border: 2px solid var(--orange);
}

.right_h > button
{
    position: absolute;
    right: 3rem;
    top: 2rem;
    color: black;
    border-radius: 15px;
}

.heart-rate
{
     display: flex;
     flex-direction: column;
     gap: 1rem;
     background-color: var(--appcolor);
     width: fit-content;
     padding: 1.5rem;
     align-items: flex-start;
     justify-content: center;
     border-radius: 5px;
     position: absolute;
     right: 4rem;
     top: 7rem;
     /* width: 4rem; */
}

.heart-rate>span:nth-of-type(1)
{
    color: var(--gray);
}
.heart-rate>span:nth-of-type(2)
{
    color: white;
    font-size: 1.5rem;
}

.hero-image
{
    position: absolute;
    top:10rem ;
    right: 12rem;
    width: 23rem;
}

.hero-image-back
{
    position:absolute;
    top: 4rem;
    right: 24rem;
    z-index: -1; 
    width: 15rem;
}

.calories
{
    display: flex;
    gap: 2rem;
    background-color: var(--calorycard);
    border-radius: 5px;
    padding: 1rem;
    width: fit-content;
    position: absolute;
    top: 35rem;
    right: 40rem;
}

.calories>img
{
    width: 3rem;
}

.calories>div
{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.calories>div>span:nth-of-type(1)
{
    color: var(--gray);
}
.calories>div>span:nth-of-type(2)
{
    color: white;
    font-size: 1.5rem;
}

.hero-blur
{
    width: 22rem;
    height: 30rem;
    left: 0;
     
}

@media screen and (max-width :786px)
{
    .hero
    {
        flex-direction: column;
    }
    .hero-blur
    {
        width: 14rem;
    }
    .the-best-ad>div
    {
        width: 56px;
    }
    .the-best-ad
    {
        width: 250px;
        margin-top: 0;
        font-size: small;
        align-self: center;
        transform: scale(0.8);
    }
    .hero-text{
        font-size: xx-large;
        align-items: center;
        justify-content: center;
    }
    .hero-text>div:nth-of-type(3)
    {
        font-size: small;
        font-weight: 200;
        letter-spacing: 1px;
        text-align: center;
    }
    .hero-button
    {
        justify-content: center;
    }
    .figures>div>span:nth-of-type(1)
    {
        font-size: large;
    }
    .figures>div>span:nth-of-type(2)
    {
        font-size: small;
    }
    .right_h
    {
        position: relative;
        background: none;
    }
    .heart-rate
    {
        left: 1rem;
        top: 2rem;
    }
    .calories{
        position: relative;
        top: 5rem;
        left: 2rem;
    }
    .calories>img
    {
        width: 2rem;
    }
    .calories>div>:nth-child(2)
    {
        color: white;
        font-size: 1rem;
    }
    .hero-image
    {
        position: relative;
        width: 15rem;
        left: 7rem;
        top: 4rem;
        align-self: center;
    }
    .hero-image-back
    {
        width: 15rem;
        left: 2rem;
        top: 0;
    }

}
